<template>
  <b-card v-if="$route.name != 'edit-club'">
    <section-menu
      v-if="sectionsInTab.length"
      :name-module="nameModule"
      :sections-in-tab="sectionsInTab"
      @assign-translate-in-x="assignTranslateInX"
    />
    <b-row
      class="pt-1"
      :class="{'i-translate-in-pi' : showTransform}"
    >
      <b-col>
        <router-view />
      </b-col>
    </b-row>
  </b-card>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import SectionMenu from '@/views/partials/menu/SectionMenu.vue'
import checkPermission from '@/auth/permissions'

export default {
  components: {
    SectionMenu,
  },

  data() {
    return {
      showTransform: false,
      sectionsInTab: [],
      sections: [
        {
          id: 1,
          route: 'corporate',
          name: 'routes.documentalCollectionRoute.corporate',
          key: ['VIEW_CORPORATE'],
          submodules: [],
        },
        {
          id: 2,
          route: 'society',
          name: 'corporate.society',
          key: ['VIEW_SOCIETY'],
          submodules: [],
        },
        {
          id: 3,
          route: 'assemblies',
          name: 'routes.documentalCollectionRoute.assemblies',
          key: ['VIEW_ASSEMBLY'],
          submodules: [],
        },
        {
          id: 4,
          route: 'power-of-attorney',
          name: 'routes.documentalCollectionRoute.powers',
          key: ['VIEW_POWER_OF_ATTORNEY'],
          submodules: [],
        },
        {
          id: 5,
          route: 'representative',
          name: 'routes.documentalCollectionRoute.representatives',
          key: ['VIEW_ASSIGN_REPRESENTATIVES'],
          submodules: [],
        },
        {
          id: 6,
          route: 'intellectual-property',
          name: 'routes.documentalCollectionRoute.intellectualProperty',
          key: [
            'VIEW_TRADEMARK_REGISTRATION',
            'VIEW_LITERARY_OR_ARTISTIC_WORK_REGISTRATION',
            'VIEW_AGREEMENT',
          ],
          submodules: [
            {
              id: 61,
              route: 'pi-agreements',
              name: 'clubs.intellectualProperty.formAgreements.labels.agreements',
              key: ['VIEW_AGREEMENT'],
            },
            {
              id: 62,
              route: 'pi-trademarks',
              name: 'clubs.intellectualProperty.trademarkRegistration',
              key: ['VIEW_TRADEMARK_REGISTRATION'],
            },
            {
              id: 63,
              route: 'pi-literary-or-artistic-works',
              name: 'clubs.intellectualProperty.literaryOrArtisticWork',
              key: ['VIEW_LITERARY_OR_ARTISTIC_WORK_REGISTRATION'],
            },
          ],
        },
      ],
      nameModule: 'routes.documentalCollectionRoute.documentalCollection',
    }
  },

  mounted() {
    this.sectionsInTab = this.sections.filter(item => this.checkPermission(item.key))
  },

  methods: {
    checkPermission,
    assignTranslateInX(pValue) {
      this.showTransform = pValue
    },
  },
}
</script>

<style scoped>
.i-translate-in-pi {
  margin-top: 54px;
}
</style>
